const MarketplaceAndPartners = () => {
  return (
    <section className="mt-4 flex flex-col gap-2">
      <h2 className="font-bold text-base">MARKETPLACE E SITES PARCEIROS</h2>
      <div className="flex flex-col gap-2">
        <p>
          A entrega dos produtos de nossos parceiros pode ocorrer de duas
          formas:
        </p>
        <p className="mt-4">
          <strong>
            Pedidos entregues pela transportadora parceria da Decathlon
          </strong>
          <br />
          Todos os pedidos entregues pela Decathlon, são enviados por
          transportadoras que temos parceria e é de nossa responsabilidade
          fornecer o rastreamento para o cliente, por meio de um e-mail
          automático. Os pedidos são encaminhados para as transportadoras em até
          48hs úteis após a confirmação de pagamento.
        </p>

        <p className="mt-4">
          <strong>Pedidos entregues pela transportadora do vendedor</strong>
          <br />
          Pedidos entregues pela transportadora dos nossos vendedores, a
          Decathlon acompanha toda a entrega para que seja realizada com
          qualidade e dentro do prazo combinado.
        </p>

        <p>
          Os pedidos são encaminhados para as transportadoras em até 48hs úteis
          após a confirmação de pagamento. As atualizações de rastreio são de
          responsabilidade do vendedor e o envio da comunicação é realizado por
          um e-mail automático.
        </p>
      </div>
    </section>
  )
}

export default MarketplaceAndPartners
