const Transport = () => {
  return (
    <section className="mt-4 flex flex-col gap-2">
      <h3 className="font-bold text-base">Transporte</h3>
      <div className="flex flex-col gap-2">
        <p>
          As transportadoras que realizam as entregas da DECATHLON não estão
          autorizadas a abrir embalagens ou realizar a entrega utilizando
          janelas, telhados ou outros meios de difícil acesso.
        </p>
        <p>
          Para realizar a entrega, o cliente tem que oferecer facilidade de
          acesso. A Decathlon não assumirá a responsabilidade por qualquer
          montagem, guincho para apartamentos ou locais com difícil acesso. No
          caso de existir alguma dificuldade que nos impeça de ter acesso
          normal, o produto é deixado no local mais próximo que o acesso
          permitir. A entrega pode ser feita a terceiros desde que autorizada
          previamente pelo comprador. Ao receber o produto, verifique as
          condições da embalagem. Caso esta esteja avariada, deverá recusar o
          recebimento do produto, anotando na Nota Fiscal a razão da recusa e
          informar o ocorrido à
          <a
            className="text-restructure-action"
            href="/atendimento"
            target="_blank"
          >
            {' '}
            Central de Atendimento
          </a>
          .
        </p>
        <p>
          Em caso de recebimento é necessário solicitar a troca ou devolução,
          confira aqui e poderá solicitar a coleta em seu endereço.
        </p>
        <p>
          Com exceção dos Correios, o pedido não pode ser retirado na
          transportadora. Em casos de impossibilidade de recebimento, o pedido
          irá retornar ao nosso Centro de Distribuição e será gerado um
          vale-troca no valor total do pedido.
        </p>
        <p>
          Durante o transporte poderá ocorrer do pedido ser parado para
          fiscalização. Retenção fiscal é um procedimento normal realizado pela
          Secretaria da Fazenda para averiguação fiscal dos produtos que entram
          na região. Como cada Estado possui sua própria regulamentação e
          infelizmente não temos uma estimativa de quando se encerra esta
          tramitação. Também não há nenhuma ação que possa ser tomada para que
          este processo se torne mais rápido ou para que a mercadoria seja
          liberada de forma prioritária. Para seguir com o cancelamento entre em
          contato com nossa Central de Atendimento.
        </p>
      </div>
    </section>
  )
}

export default Transport
