import ProductUnavailableAfterPurchase from './ProductUnavailableAfterPurchase'

const InstoreOrExpressPickup = () => {
  return (
    <section className="flex flex-col gap-2">
      <h2 className="font-bold text-base">Retirada em loja ou Expressa:</h2>
      <div className="flex flex-col gap-2">
        <p>
          Na modalidade <strong>Expressa</strong> o prazo de entrega é de até
          2hrs, após a confirmação de pagamento variando de acordo com a
          disponibilidade dos produtos em nossas unidades. Caso o produto
          escolhido não esteja disponível em uma de nossas lojas o prazo de
          entrega para Retirada em Loja é de 4 a 5 dias úteis. A disponibilidade
          é apresentada diretamente no momento do fechamento do pedido.
        </p>
        <p>
          O prazo de entrega começa a contar após a confirmação do pagamento e
          há o acréscimo de um dia útil para aprovações de pagamento ocorridas
          após às 14 horas. Os produtos ficam disponíveis para retirada dentro
          do prazo previsto de entrega. Os produtos de grande porte como
          equipamentos de Fitness são entregues desmontados e em suas embalagens
          originais. Após a finalização do pedido não é possível alterar a forma
          de entrega, solicitar adiantamento ou prioridade. Todos os prazos de
          entrega levam em consideração o estoque, a região, o processo de
          emissão da nota fiscal e o tempo de preparo do produto.
        </p>
        <p>
          Somente após o recebimento do e-mail com a confirmação de que seu
          pedido está disponível para retirada na loja selecionada é que poderá
          ser feita a retirada. Assim que receber este e-mail, basta comparecer
          ao Atendimento ao Cliente da unidade com um documento oficial com foto
          e fazer a retirada. O prazo para coleta é de até 7 dias corridos,
          contando a partir da data da sinalização de que a compra chegou à
          loja. Se o pedido não for retirado no prazo, ele será integrado ao
          estoque da loja e será disponibilizado um crédito para utilizar
          novamente no site ou em qualquer uma de nossas unidades.
        </p>
        <p>
          Não consegue fazer a retirada? Sem problemas, outra pessoa pode fazer
          no seu lugar! Para isso basta o terceiro comparecer ao atendimento ao
          cliente da loja com o número do pedido, o documento de identificação
          do comprador em forma física ou digital e um documento original
          próprio.
        </p>
        <p>
          Algumas unidades possuem estacionamento não gratuito, o valor do
          estacionamento não está incluso no frete grátis. Para confirmar o
          horário de funcionamento de todas nossas lojas basta acessar a aba
          "Nossas lojas" no rodapé do site.
        </p>
      </div>
      <ProductUnavailableAfterPurchase />
    </section>
  )
}

export default InstoreOrExpressPickup
