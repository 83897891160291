const DeliveryFailure = () => {
  return (
    <section className="mt-4 flex flex-col gap-2">
      <h3 className="font-bold text-base">Insucesso na entrega</h3>
      <div className="flex flex-col gap-2">
        <p>
          Para evitar que ocorra eventuais transtornos, a Decathlon compartilha
          com suas transportadoras parceiras o nome, endereço e telefone dos
          consumidores. Havendo dificuldade na localização do endereço, pode ser
          que receba um telefonema direto da transportadora solicitando
          informações complementares que facilitarão a conclusão da entrega.
        </p>
        <p>
          Permanecendo a adversidade, serão realizadas até 3 tentativas de
          entrega em dias diferentes com um prazo de até 48 horas úteis entre
          elas. Após a terceira tentativa sem sucesso, o pedido será devolvido e
          será gerado um vale-troca no valor total da compra (incluindo frete).
          Para seguir com o cancelamento do mesmo, é preciso entrar em contato
          com o Atendimento ao Cliente.
        </p>
        <p>
          Outra forma de comunicação do insucesso na entrega é o envio de e-mail
          para a confirmação de endereço e referências. Após recebermos o
          retorno, será realizada uma nova tentativa em até 72 horas úteis. Caso
          não tenhamos resposta em até 3 dias úteis, o pedido será devolvido e
          será gerado um vale-troca no valor total da compra (incluindo frete).
        </p>
        <p>
          Em alguns casos pode haver a alteração do prazo de entrega de forma
          involuntária tais como: dados do cadastro com informações incorretas,
          recusa no recebimento do pedido pela pessoa habilitada, condições
          climáticas desfavoráveis, greves locais e gerais, locais de destino
          com baixa população, difícil acesso ou distâncias das capitais. Se
          isto ocorrer, será feita a notificação por e-mail do incidente com o
          novo prazo para a entrega.
        </p>
      </div>
    </section>
  )
}

export default DeliveryFailure
