import ProductUnavailableAfterPurchase from './ProductUnavailableAfterPurchase'

const LockerPickup = () => {
  return (
    <section className="flex flex-col gap-2">
      <h2 className="font-bold text-base">Retirada em locker:</h2>
      <div className="flex flex-col gap-2">
        <p>
          Na modalidade Expressa o prazo de entrega é de até 2hrs, após a
          confirmação de pagamento variando de acordo com a disponibilidade dos
          produtos em nossas unidades. Caso o produto escolhido não esteja
          disponível em uma de nossas lojas o prazo de entrega para Retirada em
          Loja é de 4 a 5 dias úteis. A disponibilidade é apresentada
          diretamente no momento do fechamento do pedido.
        </p>
        <p>
          A gestão dos pedidos para retirada em locker é feita pela empresa
          Clique e Retire e no momento fazemos a entrega deste modelo
          exclusivamente para os Estados de São Paulo e Rio de Janeiro.
        </p>
        <p>
          O prazo de entrega começa a contar após a confirmação do pagamento e
          há o acréscimo de um dia útil para aprovações de pagamento ocorridas
          após às 14 horas. Os produtos são enviados dentro do prazo previsto de
          entrega e o serviço da Clique e Retire é devidamente qualificado e
          fará as entregas com toda a segurança. Após a finalização do pedido
          não é possível alterar a forma de entrega, solicitar adiantamento ou
          prioridade. Todos os prazos de entrega levam em consideração o
          estoque, a região, o processo de emissão da nota fiscal e o tempo de
          preparo do produto.
        </p>
        <p>
          Assim que o pedido chegar no locker escolhido, receberá a informação
          por SMS e por e-mail. Nesse informativo, encontrará o QR Code para
          abrir sua gaveta. Em até 3 dias, só comparecer ao local solicitado,
          aproximar o celular com o QR Code aberto no visor disponível que a
          porta que seu produto está será aberta automaticamente.
        </p>
        <p>
          Faremos envio de comunicações diariamente até que o pedido seja
          retirado. Caso não possa fazer a coleta, outra pessoa pode realizar
          desde que tenha acesso ao QR Code enviado. Se não coletado dentro do
          prazo descrito, receberá uma informação que seu pedido está retornando
          para nosso Centro de Distribuição e será gerado um vale troca no valor
          total da compra.
        </p>
      </div>
      <ProductUnavailableAfterPurchase />

      <div className="mt-4">
        <p>Como retirar o produto no e-box/locker:</p>

        <div className="max-w-[560px] mt-4">
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/ndB4dzw2qv4"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
          <iframe
            className="mt-4"
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/qK90Q3RqTXc"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
    </section>
  )
}

export default LockerPickup
