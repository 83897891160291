const GeneralRules = () => {
  return (
    <section className="mt-4 flex flex-col gap-2">
      <h2 className="font-bold text-base">Regras gerais</h2>
      <p>
        Os produtos são enviados dentro do prazo previsto de entrega e o serviço
        da transportadora utilizada pela Decathlon é devidamente qualificado e
        fará as entregas com toda a segurança. Os produtos de grande porte como
        equipamentos de Fitness são entregues desmontados e em suas embalagens
        originais. Nossas transportadoras não têm nenhuma qualificação e
        autorização para realizar a montagem dos produtos ou qualquer outro
        serviço que não seja apenas a entrega dos produtos comprados.
      </p>
      <p>
        Não fazemos entrega em Caixa Postal e pedidos endereçados dessa forma
        serão devolvidos para nosso Centro de Distribuição e será gerado um
        vale-troca no valor total do pedido. Para seguir com o cancelamento,
        entre em contato com a
        <a
          className="text-restructure-action"
          href="/atendimento"
          target="_blank"
        >
          {' '}
          Central de Atendimento
        </a>
        .
      </p>
      <p>
        Após a finalização do pedido não é possível alterar a forma de entrega,
        solicitar adiantamento ou prioridade. Todos os prazos de entrega levam
        em consideração o estoque, a região, o processo de emissão da nota
        fiscal e o tempo de preparo do produto.
      </p>
      <p>
        As entregas dos produtos da Decathlon são feitas de segunda a
        sexta-feira, exceto feriados nacionais e estaduais, dentro do horário
        comercial das 8 às 18h. É necessário disponibilizar uma pessoa
        responsável para aguardar a entrega. Em casos excepcionais poderá
        ocorrer fora do horário comercial.
      </p>
      <p>
        Quando um pedido contém mais de um produto, em alguns casos, por conta
        das dimensões e pesos da compra, podem ser entregues separadamente.
      </p>
      <p>
        Para compras já concluídas não é possível alterar o endereço nem o
        serviço de entrega. Caso tenha informado o endereço incorreto, entre em
        contato com a Central de Atendimento para solicitar o cancelamento da
        compra. Se o pacote não for entregue devido a um endereço errado, a
        transportadora o devolverá para a Decathlon e será gerado um vale-troca
        no valor total do pedido.
      </p>
    </section>
  )
}

export default GeneralRules
