const Tracking = () => {
  return (
    <section className="mt-4 flex flex-col gap-2">
      <h3 className="font-bold text-base">Rastreamento</h3>
      <div className="flex flex-col gap-2">
        <p>
          Para cada atualização no status do pedido é encaminhado
          automaticamente um e-mail com a informação. O envio é feito para o
          e-mail informado na hora da compra, não passível de alteração. O prazo
          para o rastreio estar atualizado na transportadora é de até 48 horas
          úteis após o envio.
        </p>
        <p>
          Para clientes Clube Decathlon dentro dos “Meus Pedidos” ainda é
          possível ter acesso a todos os pedidos realizados, verificar o status
          de cada um e acompanhar a entrega.
        </p>
      </div>
    </section>
  )
}

export default Tracking
