const ProductUnavailableAfterPurchase = () => {
  return (
    <section className="mt-4 flex flex-col gap-2">
      <h3 className="font-bold text-base">
        Produto indisponível após a compra
      </h3>
      <div className="flex flex-col gap-2">
        <p>
          Infelizmente, ocasionalmente, um produto pode ficar indisponível após
          finalizada a compra. Para esses casos, a Decathlon entra em contato
          diretamente com o cliente por e-mail informando da impossibilidade de
          envio deste item. Os demais serão entregues normalmente no prazo dado
          na hora da compra.
        </p>
        <p>
          Com isso, o cliente poderá optar pela restituição do valor pago na
          peça, realizar o cancelamento total da compra ou a substituição por
          outro produto com qualidade semelhante e de igual preço. Será
          necessário responder o e-mail encaminhado ou entrar em contato com
          nossa
          <a
            className="text-restructure-action"
            href="/atendimento"
            target="_blank"
          >
            {' '}
            Central de Atendimento
          </a>
          .
        </p>
      </div>
    </section>
  )
}

export default ProductUnavailableAfterPurchase
