const DeliverySection = () => {
  return (
    <section className="mt-4">
      <p>
        Temos diversas opções de entrega para você escolher. Você pode consultar
        a disponibilidade do tipo de entrega para sua região, digitando o cep do
        endereço, no carrinho de compras.
      </p>

      <div className="mt-4 flex flex-col gap-4">
        <p>
          <strong>Normal:</strong> A entrega é realizada com prazo de até 7
          (sete) dias úteis nas principais para as principais capitais das
          regiões Sudeste, Sul e Centro-oeste, até 12 (doze) dias úteis para
          Nordeste e até 17 (dezessete) dias úteis para região Norte. O prazo de
          entrega começa a contar após a confirmação do seu pagamento e há o
          acréscimo de um dia útil para aprovações de pagamento ocorridas após
          às 14 horas.
        </p>

        <p>
          <strong>Econômica:</strong> A entrega é realizada com prazo de até 9
          (nove) dias úteis para as regiões Sudeste, Sul e Centro-oeste, até 14
          (catorze) dias úteis para região Nordeste e até 19 (dezenove) dias
          úteis para região Norte. O prazo de entrega começa a contar após a
          confirmação do seu pagamento e há o acréscimo de um dia útil para
          aprovações de pagamento ocorridas após às 14 horas.
        </p>
        <p>
          <strong>Rápida:</strong> Para São Paulo capital e regiões adjacentes
          você recebe no próximo dia útil. Para verificar se sua região está
          dentro da área de abrangência, verifique na página de pagamento o
          prazo para recebimento de sua compra. Lembrando que somente os pedidos
          com o pagamento confirmado no sistema até as 17h estão aptos. Para
          demais regiões elegíveis o prazo estimado de entrega é em até 03 dias
          úteis
        </p>
        <p>
          <strong>Agendada:</strong> O agendamento será realizado de acordo com
          os períodos disponíveis no calendário, no momento do fechamento do
          pedido.
        </p>
      </div>
    </section>
  )
}

export default DeliverySection
