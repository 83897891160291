import SidebarService from 'src/components/ui/SidebarService/SidebarService'

import DeliverySection from './DeliverySection'
import GeneralRules from './GeneralRules'
import Tracking from './Tracking'
import Transport from './Transport'
import DeliveryFailure from './DeliveryFailure'
import DeliveryCheck from './DeliveryCheck'
import LockerPickup from './LockerPickup'
import MarketplaceAndPartners from './MarketplaceAndPartners'
import InstoreOrExpressPickup from './InStoreOrExpressPickup'

import '../styles.scss'

const PoliticaEntrega = () => {
  return (
    <>
      <section className="w-full section-services delivery-policy_container">
        <div className="av-container">
          <div className="av-row section-services__flex">
            <div className="av-col-xs-24 av-col-md-7 section-services__column">
              <SidebarService />
            </div>

            <div className="av-col-xs-24 av-col-md-17">
              <div className="services-content">
                <h1 className="text-2xl italic font-bold text-center text-rest text-restructure-action">
                  POLÍTICA DE ENTREGA
                </h1>
                <DeliverySection />
                <GeneralRules />
                <Tracking />
                <Transport />
                <DeliveryFailure />
                <DeliveryCheck />
                <InstoreOrExpressPickup />
                <LockerPickup />
                <MarketplaceAndPartners />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default PoliticaEntrega
