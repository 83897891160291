import ProductUnavailableAfterPurchase from './ProductUnavailableAfterPurchase'

const DeliveryCheck = () => {
  return (
    <section className="mt-4 flex flex-col gap-2">
      <h3 className="font-bold text-base">Acareação da entrega</h3>
      <div className="flex flex-col gap-2">
        <p>
          Quando o consumidor não reconhece a entrega é necessário entrar em
          contato com nossa Central de Atendimento para solicitar a averiguação
          dos fatos.
        </p>
        <p>
          O nosso time irá solicitar uma acareação com a transportadora para
          verificar o comprovante de entrega no qual devem constar as
          assinaturas do entregador e do recebedor do produto. O prazo para o
          processo de acareação ser finalizado é de 96 horas úteis após a
          solicitação. O prazo para o consumidor solicitar a acareação é de 10
          dias úteis a partir da confirmação da entrega.
        </p>
        <p>
          Caso tenha recebido o pedido, mas esteja faltando um produto que
          consta na nota fiscal é necessário que entre em contato com nossa
          Central de Atendimento no prazo de até 96 horas úteis após o
          recebimento para que possamos averiguar.
        </p>
      </div>
      <ProductUnavailableAfterPurchase />
    </section>
  )
}

export default DeliveryCheck
